.header {
    /* dev */
    display: flex;
    flex-direction: column;
    padding-top: 0;
    min-width: 200px;
    width: 200px;
    color: white;
    height: 100vh;
    border: #ffffff0e;
    border-style: solid;
    border-width: 0px 1px 0px 0px;
    max-height: 100vh;
}

.header img {
    max-width: 120px;
}

.logo img {
    width: 100%;
    margin-top: 6px; 
    margin-bottom: 6px; 
    cursor: pointer;
}
.logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-top {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}

.logo-top img {
   max-width: 120px;
   cursor: pointer;
}

.search-bar input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: #ffffff23;
    border-radius: 5px;
    border: none;
    max-width: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px;
}
.search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    transition: all 0.22s ease;
    padding: 0;
    padding-right: 10px;
}
.search-button:hover {
    background-color: #ffffff0e;
    border-radius: 5px;
}

.nav-bar {
    max-height: 100%;
}

.nav-list {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    margin: 0;
    text-align: left;
    text-decoration: none;
    list-style-type: none;
}

.list-item {
    display: flex;  
    align-items: center;
    margin: 0;
    padding: 0.5rem 0;
    padding: 10px;
    display: flex;
    align-items: center;
    transition: all 0.22s ease;
    cursor: pointer;
}
.list-item:hover {
    background-color: #ffffff0e;
}
.list-item.active {
    background-color: #ffffff0e;
}
.list-item:first-child {
    margin-top: 0;
}
.list-item a {
    color: rgba(255, 255, 255, 0.918);
    font-size: 0.9rem;
    font-weight: 500;
    text-decoration: none;
    margin-left: 12px;
}
.list-item img {
    width: 24px;
    height: 24px;
    border-radius: 100vw;
}

.reacticon {
    margin-left: 8px;
}

.bottom-box {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;
    min-width: 200px;
    bottom: 0px;
    gap: 10px;
}

.sidebar-links {
    display: flex;
    flex-direction: row;
    margin: 0;
    text-align: left;
    text-decoration: none;
    list-style-type: none;
    min-width: 180px;
    justify-content: space-between;
    border: #ffffff0e;
    border-style: solid;
    border-width: 1px 0px 0px 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
}
.sidebar-links svg {
    padding:10px;
    transition: all 0.22s ease;
}
.sidebar-links svg:hover {
    background-color: #ffffff0e;
    border-radius: 5px;
    cursor: pointer;
}

.login {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
}
.login svg {
    padding: 10px;
}

.user {
    display: flex;
    min-width: 50%;
    font-size: 0.8rem;
    justify-content: start;
    align-items: center;
    transition: all 0.22s ease;
}

.signin {
    display: flex;
    min-width: 50%;
    font-size: 0.7rem;
    justify-content: end;
    align-items: center;
    transition: all 0.22s ease;
    margin-left: auto;
    margin-right: auto;
}
.signin:hover {
    background-color: #ffffff0e;
    border-radius: 5px;
    cursor: pointer;
}
.signin p {
    margin-right: 10px;
}

.explore-menu {
    display: flex;
    align-items: center;
    height: 24px;
    padding: 10px;
    transition: all 0.22s ease;
}
.explore-menu:hover {
    background-color: #ffffff0e;
    border-radius: 5px;
    cursor: pointer;
}
.explore-menu.active {
    background-color: #ffffff0e;
}

.explore-menu p {
    margin-right: 20px;
    font-weight: 600;
    margin-right: 10px;
}

.menuimg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    margin-left: 10px;
}

@media screen and (max-width: 768px) {
    .header {
        display: none;
    }   
    .page {
        display: flex;
        flex-direction: column;
    }
    .header-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    ::-webkit-scrollbar {
        display: none;
    }
}

.rightt {
    display: flex;
    align-items: center;
    margin-right: 0px;
}

.header-top {
    display: hidden;
    align-items: center;
    justify-content: space-between;
}

.searchbtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    transition: all 0.22s ease;
    padding: 8px 16px;
    background-color: #ffffff23;
    color: white;
    cursor: pointer;
    transition: all 0.22s ease;
    width: 90%;
}
.searchbtn:hover {
    background-color: #ffffff3b;
    border-radius: 5px;
}

.borderbox {
    border: #ffffff63;
    border-style: solid;
    border-width: 1px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 5px;
    opacity: 0.5;
}
