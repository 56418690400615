@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root {
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --bg: #111116;
}

body {
  font-family: 'Poppins', sans-serif;
  color: var(--light);
  margin: 0;
  background-color: var(--bg);
  max-height: 100vh;
  overflow-y: hidden;
}

.page {
  font-weight: 600;
}

.App {
  text-align: center;
  background-color: var(--bg);
  margin: 0;
}

.linebreak {
  border-bottom: 1px solid #ffffff0e;
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.flex {
  display: flex;
  align-items: center;
}
