.tabs-container {
    display: flex;
    padding: 6px;
}

.tab {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    padding: 6px;
    border-bottom: 3px solid #e2e2e21e;
    transition: all 0.22s ease;
    cursor: pointer;
}
.tab:hover {
    border-bottom: 3px solid #dbdbdb62;
}
.tab.active {
    border-bottom: 3px solid #fff890;
}

.tab img {
    width: 24px;
    height: 24px;
    margin-right: 4px;
}

.tab p { margin: 0; }

/* Mobile  */

@media (max-width: 768px) {
    /* Less padding */
    .tabs-container {
        padding: 0px;
        margin-bottom: 6px;
    }
    .tab {
        padding: 3px;
        margin-left: 10px;
        margin-right: 0px;
    }

    .tab img {
        width: 16px;
        height: 16px;
    }
}