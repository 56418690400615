.search-modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;
    min-width: 700px;
    bottom: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    gap: 10px;
    background-color: #111111e0;
    border-radius: 5px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    z-index: 10;
    max-height: 100vh;
}

.search-modal-content {
    margin-top: 40px;
    background-color: rgb(8, 8, 12);
    z-index: 11;
}

.search-modal-body {
    background-color: rgb(8, 8, 12);
}

.modalinput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: #ffffff23;
    border-radius: 5px;
    border: none;
    margin-left: auto;
    margin-right: auto;
    min-width: 700px;
}

.borderbox2 {
    border: #ffffff13;
    border-style: solid;
    border-width: 1px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 5px;
}