@keyframes scroll {
    0% { transform: translateX(0); }
    100% { transform: translateX(-50%); }
  }
  
  .trending-container {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    background-color: #111111d2;
    padding: 10px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .paid-container {
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    background-color: #111111d2;
    padding: 10px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .trending-content {
    display: inline-flex;
    animation: scroll 40s linear infinite;
    animation-delay: 2s; 
    animation-play-state: running; 
  }
  .trending-content:hover {
    animation-play-state: paused; /* Pause the animation on hover */
  }

  .token {
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 0 5px;
    text-align: center;
    border: 1px transparent solid;
    border-radius: 5px;
    padding: 4px 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
  .token:hover {
    border: 1px solid #2d2d2d;
    background-color: #2d2d2d;
  }
  
  .token img {
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
  
  .token-name {
    font-size: 14px;
    color: #ffffff;
    margin-right: 4px;
  }

  .faded {
    opacity: 0.5;
    font-size: 13px;
    font-weight: 300;
  }

  .percentage {
    font-size: 14px;
    margin-right: 4px;
  }

  .banner {
    display: flex;
    width: 100%;
}

  .image {
    min-width: 20px;
    min-height: 20px;
  }

  .noborder {
    border: none;
  }