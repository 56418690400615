.page {
  max-width: 100vw;
  margin: 0;
  height: 100vh;
  overflow: hidden;
}

.main-section {
  max-width: 70vw;
  width: 70vw;
  position: relative;
  background-color: #2c2f36;
}
  
  .recent-transactions {
    background-color: #2c2f36;
    display: flex; /* Use flexbox */
    flex-direction: column; /* Arrange children vertically */   
    position: relative;
    height: 54vh;
    max-width: 70vw;
    width: 70vw;
}

.table-container {
    margin-top: 0;
    overflow-y: auto; /* Enable vertical scrolling */
}
  
  table {
    width: 100%;
    border-collapse: collapse; /* Ensure no default spacing between cells */
  }
  
  thead th {
    background-color: #27282e;
    color: #ffffff;
    padding: 10px;
    font-size: 14px;
    text-align: right;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  
  thead th:first-child {
    text-align: left;
    border-left: none; /* Remove left border of the first column */
  }
  
  thead th:last-child {
    border-right: none; /* Remove right border of the last column */
  }
  
  tbody tr:nth-child(odd) {
    background-color: #16181d;
  }
  
  tbody tr:nth-child(even) {
    background-color: #16181dda;
  }
  
  tbody tr:hover {
    background-color: #44475030;
  }
  
  td {
    padding: 10px;
    color: #ffffff;
    font-size: 14px;
    text-align: right;
    border-right: 1px solid #25272b; /* Add border between columns */
    border-bottom: 1px solid #25272b; /* Add border between rows */
  }
  
  td:first-child {
    text-align: left;
    border-left: none; /* Remove left border of the first column */
  }
  
  td:last-child {
    border-right: none; /* Remove right border of the last column */
  }
  
  .buy {
    color: #00c076;
  }
  
  .sell {
    color: #ff5b5b;
  }
  
  a {
    color: #ffffff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }

  .content2 {
    display: flex;
    width: 100%;
    background-color: #0f1011;
    overflow: auto;
    max-height: 100ch;
    overflow-x: hidden;
    position: relative;
}

.table-container {
  flex: 1; /* Grow to take up remaining space */
  overflow-y: auto; /* Enable vertical scrolling */
}

.table-container::-webkit-scrollbar {
  width: 10px;
}
.table-container::-webkit-scrollbar-thumb {
  background-color: #636363;
  border-radius: 10px;
}
.table-container::-webkit-scrollbar-track {
  background-color: #202020;
}

.chart-container {
  width: auto;
  display: flex;
  flex-direction: column;
  height: 40vh;
}

/* Mobile view */
@media (max-width: 768px) {
  .content2 {
    flex-direction: column-reverse;
    justify-content: flex-start;
    font-weight: 400;
  }
  .table-container {
    max-height: 50vh;
  }
  .main-section {
    max-width: 100%;
  }
}

.showchart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  background-color: none;
  background: none;
  color: white;
  border: 1px solid #303030;
  font-size: 14px;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 100vw;
}

@keyframes flash {
  0% { background-color: transparent; 
    transform: translateX(-100%);
    opacity: 0;}
  30% { background-color: rgba(255, 255, 204, 0.308); 
    transform: translateX(-100%);
    opacity: 0;}
  80% { background-color: transparent;
    transform: translateX(0);
    opacity: 1;}
}

.flash {
  animation: flash 0.1s ease-in-out;
}

.search-result {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  background-color: #141516;
  margin-right: auto;
  width: 100%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin-top: 10px;
  max-height: 80vh;
}
.search-result:hover {
  background-color: #44475030;
}

.search-result-index {
  font-size: 13px;
  color: #ffffff;
  opacity: 0.5;
  margin-right: 8px;
}

.search-result-token {
  font-size: 13px;
  color: #ffffff;
  font-weight: 600;
  opacity: 0.2;
  margin-top: 6px;
  transition: all 0.2s ease-in-out;
}
.search-result-token:hover {
  opacity: 1;
}

.search-results {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.close-search {
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-right: auto;
  width: fit-content;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin-top: -10px;
  margin-left: -28px;
}
.close-search:hover {
  background-color: #44475030;
}

.searchcontainer {
  position: absolute;
  z-index: 100;
  background-color: #0f1011;
  padding-bottom: 10px;
  padding-top: 0px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  top: 100px;
  left: 0;
  right: 0;
  max-height: 80vh;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  animation: flash2 0.5s ease-in-out infinite;
}

/* Animation to make loading text flash nicely */

@keyframes flash2 {
  0% {  
    opacity: 0;}
  50% {  
    opacity: 1;}
  80% { 
    opacity: 1;}
}
