html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.table-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    padding: 0;
}

.token-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    flex-grow: 1; /* Ensure the table takes up remaining space */
}

.token-table th {
    background-color: #282d35;
    border-right: 1px solid #363434;
    padding: 6px;
    text-align: right;
    text-transform: uppercase;
    font-size: 0.75rem;
    transition: all 0.22s ease;
    white-space: nowrap;
}

.token-table th:hover {
    cursor: pointer;
    text-decoration: underline;
}

.token-table th:first-child {
    text-align: left;
}

.token-table td {
    border-right: 1px solid #dddddd13;
    border-bottom: 1px solid #dddddd13;
    padding: 8px;
    text-align: left;
    text-transform: uppercase;
    font-size: 14px;
}

.token-table tbody tr {
    transition: all 0.22s ease;
    cursor: pointer;
    white-space: nowrap;
}

.token-table tbody tr:hover {
    background-color: #44475030;
}

.price { 
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.opaque {
    opacity: 0.5;
    transition: all 0.22s ease;
}

.opaque:hover {
    opacity: 1;
}

.popup {
    position: absolute;
    z-index: 10;
    font-size: 0.85rem;
    background-color: #0e0e0e;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    width: fit-content;
    margin-top: 20px;
    transform: translateX(-50%);
    text-transform: none;
}

.popup2 {
    position: absolute;
    z-index: 10;
    font-size: 0.85rem;
    background-color: #0e0e0e;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    width: fit-content;
    margin-top: 20px;
    text-transform: none;
}

.rowimg {
    max-width: 20px;
    max-height: 20px;
    border-radius: 100vw;
}

p {
    margin: 0;
    padding: 0;
    display: inline;
    white-space: nowrap;
}

@keyframes flash {
    0% { background-color: transparent; }
    30% { background-color: rgba(255, 255, 204, 0.308); }
    80% { background-color: transparent; }
}

.flash {
    animation: flash 0.5s ease-in-out;
}

/* Mobile view smaller fonts padding */
@media (max-width: 768px) {
    .token-table th, .token-table td {
        padding: 4px;
        font-size: 0.6rem;
    }
    .popup, .popup2 {
        font-size: 0.7rem;
    }
    .price {
        font-size: 0.8rem;
    }
}
