.details-container {
    position: relative;
    background-color: hsl(240, 3%, 6%);
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    width: 20vw;
}
.token-details {
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
}
.line {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    gap: 8px;
}
.data-box {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    border: 1px solid #44475070;
    border-radius: 10px;
    padding: 4px 8px;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 14px;
    position: relative;
}

.bar-container {
    display: flex;
    width: 100%;
    height: 6px; /* Adjust height as needed */
    border-radius: 10px;
    overflow: hidden;
    margin-top: auto;
    margin-bottom: auto;
}

.bar {
    height: 100%;
}

.bar.red {
    background-color: #e57373;
}

.bar.green {
    background-color: #81c784;
}
.data-box h2 {
    font-size: 14px;
    font-weight: 200;
    margin-bottom: 8px;
    white-space: nowrap;
    margin: 0;
    opacity: 0.5;
}
.data-box td {
    font-weight: 600;
    margin: 0;
    white-space: nowrap;
    text-align: center;
    border: none;
    padding: 0;
}

.data-box p {
    font-weight: 600;
    margin: 0;
    white-space: nowrap;
}

.data-box2 {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    border: 1px solid #44475070;
    padding: 8px 16px;
    margin-bottom: 8px;
    border-right: none;
    font-size: 13px;
}
.data-box2:first-child {
    border-radius: 10px 0px 0px 10px;
}
.data-box2:last-child {
    border-radius: 0px 10px 10px 0px;
    border-right: 1px solid #44475070;
}
.data-box2 h2 {
    font-size: 12px;
    font-weight: 200;
    margin-bottom: 8px;
    white-space: nowrap;
    margin: 0;
    opacity: 0.5;
}
.data-box2 p {
    font-weight: 600;
    margin: 0;
    white-space: nowrap;
}
.line2 {
    display: flex;
    justify-content: center;
}
.red-tone {
    color: #e57373; /* salmon-like red color */
}
.green-tone {
    color: #81c784; /* nice green color */
}

@keyframes flash {
  0% { background-color: transparent; }
  30% { background-color: rgba(255, 255, 204, 0.308); }
  80% { background-color: transparent; }
  }
  
  .flash {
    animation: flash 0.5s ease-in-out;
}

.tradebtn {
    display: flex;
    justify-content: center;
    width: 90%;
    border-radius: 10px;
    background: none;
    border: 1px solid #222222;
    color: white;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
}

.tradebtn p {
    margin: 0;
    margin-left: 8px;
}

.tradebtn:hover {
    background: #444750;
    border: 1px solid #444750;
    cursor: pointer;
}

.two {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 8px;
}

.disabled {
    background: none;
    border: 1px solid #44475050;
    color: white;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    cursor: not-allowed;
    opacity: 0.5;
}

.textline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #44475050;
    color: white;
    padding: 8px 0px;
    font-size: 14px;
}

.textline p {
    margin: 0;
    padding: 0;
    line-height: auto;
}  

.copyBox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background: #44475050;
    border: 1px solid #44475050;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    cursor: pointer;
    transition: all 0.3s;
}
.copyBox:hover {
    background: #444750;
    border: 1px solid #444750;
    cursor: pointer;
}

.link {
    color: #8e91a591;
    text-decoration: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 2px;
    transition: all 0.3s;
}
.link:hover {
    color: white;
    text-decoration: underline;
    cursor: pointer;
}

.gap {
    gap: 8px;
}

.poolinfo {
 margin-top: 10px;
 margin-left: 20px;
 margin-right: 20px;
}

.highlighted-border {
    border: 1px solid #f6ff77;
}
.highlighted-border:hover {
    border: 1px solid #65ff28;
    cursor: pointer;
}

.smaller {
    font-size: 13px;
    align-items: center;
    padding: 4px;
    border-radius: 6px;
}

.advert {
    position: relative;
    background-color: rgb(10, 10, 10);
    border-radius: 10px;
    padding: 8px;
    margin-left: 20px;
    margin-right: 20px;
}

.adtag {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #000000;
    color: white;
    padding: 4px 8px;
    margin: 8px;
    border-radius: 0px 10px 0px 10px;
    font-size: 12px;
    font-weight: 600;
    z-index: 10000;
}

.btmtxt {
    position: absolute;
    bottom: -24px;
    left: 0;
    color: white;
    padding: 4px 8px;
    border-radius: 10px 0px 10px 0px;
    font-size: 11px;
    font-weight: 200;
    opacity: 0.5;
    cursor: pointer;
    transition: all 0.3s;
}
.btmtxt:hover {
    opacity: 1;
    cursor: pointer;
}

.advert img {
    width: 60px;
    height: 60px;
    border-radius: 100vw;
    margin-right: 10px;
}

.advert h2 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    text-wrap: wrap;
}

.advert p {
    font-size: 12px;
    font-weight: 300;
    text-wrap: wrap;
}

.advert .links {
    display: flex;
    justify-content: space-evenly;
    margin-top: 8px;
}

.linkk {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1b1b1f;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 300;
    gap: 6px;
    margin: 3px;
    color: white;
    opacity: 0.9;
    min-height: 30px;
    min-width: 30px;
    transition: all 0.3s ease-in-out;
}
.linkk:hover {
    background-color: #444750;
    cursor: pointer;
}

.wid {
    width: 100%;
}

.inline {
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat-container2 {
    position: sticky;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: hsl(0, 0%, 13%);
    height: 50%;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    cursor: pointer;
    transition: all 0.3s;
}
.chat-container2:hover {
    background-color: hsl(0, 0%, 15%);
}