.advert-container {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #64646413;
    padding: 8px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 6px;
    margin-bottom: 6px;
    border-radius: 5px;
    transition: all 0.22s ease;
    text-align: left;
    border: 1px solid #88888811;
}
.advert-container:hover {
    background-color: #ffffff0a;
}

.ad-text {
    display: flex;
    justify-content: center;    
    margin-right: 6px;
}

.ad {
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-right: 20px;
    width: 100%;
}

.ad1 {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 0.9rem;
    font-weight: 600;
    color: #bbcbf7;
    margin-right: 8px;
} 

.ad2 {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 0.8rem;
    font-weight: 400;
}

a {
    color: #bbcbf7;
    text-decoration: none;
    text-decoration: underline #bbcbf7;
    opacity: 0.5;
    font-size: 0.7rem;
    transition: all 0.22s ease;
}
a:hover {
    opacity: 1;
}

.options {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 0;
    gap: 4px;
}
.options p {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 400;
    color: #bbcbf7;
    opacity: 0.5;
}
.options svg {
    padding: 5px;
    margin: 0;
    opacity: 0.5;
    transition: all 0.22s ease;
    cursor: pointer;
}

.admain {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}
.admain img {
    margin-right: 8px;
    min-height: 40px;
    height: 40px;
    min-width: 40px;
    width: 40px;
}

@media screen and (max-width: 768px) {
    .ad-text {
        flex-direction: column;
    }
    .admain {
        margin-bottom: 6px;
    }
    .ad {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    .admain img {
        height: 20px;
        min-height: 20px;
        width: 20px;
        min-width: 20px;
    }
}