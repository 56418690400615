form {
    display: flex;
    flex-direction: column;
    margin: 40px;
}

input {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    background-color: rgb(10, 10, 10);
    background-color: rgb(10, 10, 10);
    color: white;
}

textarea {
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    background-color: rgb(10, 10, 10);
    color: white;
    min-height: 150px;
    min-width: 300px;
}

button {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
    cursor: pointer;
    transition: all 0.22s ease;
}

button:hover {
    background-color: #e1e1e1;
}

