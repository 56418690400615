.page {
    display: flex;
    max-height: 100ch;
}

.header-top {
    display: none;
    position: relative;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 0;
    margin: 0;
    min-width: 100%;
    max-height: fit-content;
    color: white;
}

.content {
    width: 100%;
    background-color: #030208;
    max-height: 100vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative; /* Add this to establish a positioning context */
}

.top {
    height: auto;
    max-height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
/* Token page */
.trending-banner {
    min-width: 100px;
    background-color: grey;
    height: 50px;
    margin: 0;
}

.chart-container {
    width: 100%;
    height: 400px;
}

.bottom {
    width: 100%;
    overflow: auto;
    position: relative; 
}

::-webkit-scrollbar {
    width: 10px;
  }
::-webkit-scrollbar-thumb {
    background-color: #636363;
    border-radius: 10px;
  }
::-webkit-scrollbar-track {
    background-color: #202020;
  }

  .page-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px; /* Add padding for spacing */
    background-color: #26272900; /* Match your header background color */
    color: white; /* Text color */
    border-radius: 5px; /* Rounded corners */
    font-size: 12px;
  }
  
  .page-controls button {
    padding: 8px 16px;
    border-radius: 100vw;
    margin: 0 8px; /* Adjust spacing between elements */
    background-color: #1a1a1a1f;
    border: none;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .page-controls button:hover {
    background-color: #444;
  }
  
  .page-controls span {
    margin-left: 6px; /* Adjust spacing between elements */
    margin-right: 0px;
    opacity: 0.4;
  }

  .filter-container {
    display: flex;
    padding-top: 0;
    width: 100%;
    margin-left: 10px;
    margin-top: 6px;
    margin-bottom: 6px;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth; /* Optional: Add smooth scrolling */
    -webkit-overflow-scrolling: touch; /* Optional: Enable smooth scrolling on iOS */
}
/* Hide scrollbar */
.filter-container::-webkit-scrollbar {
    display: none;
}

.time-filter {
    position: relative;
    display: flex;
    background-color: rgb(98, 98, 247);
    border-radius: 5px;
    padding: 5px;
    justify-content: space-evenly;
    align-items: center;
    width: fit-content;
    transition: all 0.22s ease;
    margin-right: 20px;
}
.time-filter:hover {
    cursor: pointer;
    background-color: rgb(96, 96, 255, 0.8);
}
.time-filter p {
    white-space: nowrap;
    margin: 0;
    padding: 0;
    color: white;
    font-size: 0.8em;
    font-weight: 600;
    padding: 5px;
    min-width: 28px;
}
.time-filter svg {
    padding: 5px;
    margin: 0;
}

.time-periods {
    position: absolute;
    color: white;
    margin-top: 40px;
    background-color: rgb(17, 17, 17);
    z-index: 100;
    border-radius: 5px;
    padding: 10px;
    text-align: left;
}

.time-period {
    font-size: 0.8rem;
    font-weight: 400;
    border-radius: 4px;
    padding: 3px 8px;
    z-index: 2;
    opacity: 0.6;
    transition: all 0.22s ease;
}
.time-period:hover {
    cursor: pointer;
    background-color: rgba(39, 39, 39, 0.8);
    opacity: 1;
}

.trending-filter {
    display: flex;
    background-color: rgba(255, 255, 255, 0.034);
    border-radius: 5px;
    padding: 5px;
    justify-content: space-evenly;
    align-items: center;
    width: fit-content;
    transition: all 0.22s ease;
    margin-right: 20px;
}
.trending-filter.active {
    background-color: rgb(96, 96, 255);
}

.trending-filter:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
}
.trending-filter.active:hover {
    background-color: rgb(96, 96, 255);
}

.trending-filter p {
    margin: 0;
    padding: 0;
    color: white;
    font-size: 0.8em;
    font-weight: 600;
    padding: 5px;
}
.trending-filter svg {
    padding: 5px;
    margin: 0;
}

.timeframe {
    font-size: 0.75rem;
    font-weight: 600;
    background-color: rgb(60, 60, 151);
    border-radius: 4px;
    padding: 3px 8px;
    margin-left: 4px;
    z-index: 2;
    transition: all 0.22s ease;
}
.timeframe:hover {
    cursor: pointer;
    background-color: rgba(39, 39, 39, 0.8);
}
.timeframe.active {
    background-color: rgb(255, 255, 255);
    color: rgb(60, 60, 151);
}

.buttons {
    white-space: nowrap;
    font-size: 0.7rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-right: 10px;
    background-color: rgba(255, 255, 255, 0.034);
    padding: 5px;
    border-radius: 5px;
    transition: all 0.22s ease;
}
.buttons p {
    margin-right: 4px;
}
.buttons.active {
    background-color: rgb(96, 96, 255);
}
.buttons:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
    opacity: 1;
}
.buttons.active:hover {
    background-color: rgb(96, 96, 255);
}
.buttons svg {
    margin-right: 8px;
}


.timeframebtn {
    font-size: 0.75rem;
    font-weight: 600;
    background-color: rgb(60, 60, 151);
    border-radius: 4px;
    padding: 3px 8px;
    margin-left: 4px;
    z-index: 2;
    transition: all 0.22s ease;
}
.timeframebtn:hover {
    cursor: pointer;
    background-color: rgba(39, 39, 39, 0.8);
}
.timeframebtn.active {
    background-color: rgb(255, 255, 255);
    color: rgb(60, 60, 151);
}


.logo img {
    width: 100%;
    margin-top: 6px; 
    margin-bottom: 6px; 
    cursor: pointer;
}
.logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-bar input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: #ffffff23;
    border-radius: 5px;
    border: none;
    max-width: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px;
}
.search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 5px;
    transition: all 0.22s ease;
    margin-right: 40px;
}
.searchbutton {
    padding: 10px;
}
.search-button:hover {
    background-color: #ffffff0e;
    border-radius: 5px;
}

/* Smaller fonts on mobile width */

@media (max-width: 768px) {
    body {
        font-size: 12px;
    }
}

.spacer {
    height: 10px;
}