.chat-container {
    width: 100%;
    max-width: 100%;
    height: 84%;
}
  
  .chat-header {
    justify-content: space-between;
    align-items: center;
    padding-top: 6px;
    background-color: #1d6cff;
    padding-bottom: 6px;
    height: 7%
  }
  
  .chat-messages {
    flex: 1;
    padding: 10px;
    font-size: 14px;
    overflow-y: auto;
    background-color: #161616;
    height: 85%;
  }
  
  .chat-messages ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .chat-messages li {
    margin-bottom: 10px;
  }
  
  .chat-input {
    display: flex;
  }
  
  .chat-input input {
    flex: 1;
    background-color: #ffffff13;
    padding: 10px;
    border: none;
    color: white;
    outline: none;
    border-radius: 0px 0px 0px 0px;
  }
  .chat-input input:disabled {
    opacity: 0.7
  }
  
  .chat-input button {
    padding: 10px;
    background-color: #007bff;
    color: rgb(255, 255, 255);
    border: none;
    cursor: pointer;
    border-radius: 0px;
  }
  
  .chat-input button:hover {
    background-color: #0056b3;
  }
  
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn:disabled {
    opacity: 0.4
  }

  .btn svg {
    margin-right: 2px;
  }

  .chat-header {
    display: flex;
    justify-content: flex-end;
  }

  .right {
    background-color: none;
    background: none;
    border: 1px solid rgb(255, 255, 255);
    color: white;
    font-size: 10px;
    border-radius: 4px;
    padding: 5px 10px;
    margin-bottom: 6px;
    margin-left: auto;
    margin-right: 0px;
    margin-top: 4px;
  }

  .voice-chat {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }

  .voice-chat p {
    margin: 0;
    margin-right: 8px;
    font-size: 11px;
    opacity: 0.8;
  }

  .name {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 0;
    margin-bottom: 4px;
    text-align: center;
    margin-bottom: 10px;
  }

  .name h1 {
    font-size: 18px;
    margin: 0;
  }

  .name img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .wide {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 200;
    font-size: 12px;
  }

  .wide strong {
    font-weight: 600;
    font-size: 13px;
  }

  .timestamp {
    color: rgb(255, 255, 255);
    font-size: 10px;
    margin-left: 10px;
    opacity: 0.3;
  }
  
.mic {
    transition: all 0.3s ease;
    border: 1px solid #ffffff40;
    border-radius: 100vw;
    padding: 4px;
}
  .mic:hover {
    background-color: #363636;
    border: 1px solid rgb(110, 255, 65);
    opacity: 1;
    cursor: pointer;
  }

  /* Modern rounded and transparent scrollbar similar to ios */

    .chat-messages::-webkit-scrollbar {
        width: 10px;
    }
    .chat-messages::-webkit-scrollbar-thumb {
        background-color: #636363;
        border-radius: 10px;
    }
    .chat-messages::-webkit-scrollbar-track {
        background-color: #202020;
    }
    
    .message {
      word-break: break-all;
      text-wrap: normal;
    }


    @media (max-width: 768px) {
      .chat-container {
        max-width: 100%;
      }
    }

    .username {
      color: white;
      font-size: 12px;
      font-weight: 600;
      margin-right: 10px;
      transition: all 0.3s ease;
    }
    .username:hover {
      text-decoration: underline;
      color: #fffc58;
    }

    @keyframes flash {
      0% { background-color: transparent; }
      30% { background-color: rgba(255, 255, 144, 0.589); }
      80% { background-color: transparent; }
      }
      
      .flash {
        animation: flash 0.5s ease-in-out;
    }

    .reactions {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 18px;
    }
    .reactions button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      border: 1px solid #343436;
      color: white;
      padding: 10px 22px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      text-decoration: none;
      border-radius: 10px;
    }
    .reactions button:hover {
      background: #444750;
      border: 1px solid #444750;
      cursor: pointer;
    }
    .reactions span {
      margin-top: 3px;
      margin-left: 6px;
    }

    .green {
      color: #81c784;
    }

    .red {
      color: #e57373;
    }

    .minitext {
      font-size: 11px;
      color: #ffffff80;
      margin-top: 4px;
    }